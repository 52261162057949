import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Head from 'Components/core/Head';

export default class PublicSurveyConfirm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        return (
            <StyledComponent
                className="component-survey-confirm-page" 
                styles={require('./styles')}
            >
                <Head
                    title="meta.title"
                    description="meta.description"
                />
                <LayoutContainer>
                    <h1 className="page-headline">Dziękujemy</h1>
                    <p className="page-subheadline">Pobierz aplikacje...</p>
                    <p className="page-subheadline">Twoja płatność została potwierdzona. Możesz teraz zalogować się na swoje konto</p>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
