import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        padding: '2em',
        paddingTop: '6em',
        minHeight: '85vh',
        background: variables.dpc_backgroundWhite,
        color: variables.dpc_fontDark,
        textAlign: 'center',

        '.page-headline': {
            fontSize: '3em',
            fontWeight: 600,
            marginBottom: '.5em',
        },

        '.page-subheadline': {
            fontSize: '1.3em',
            fontWeight: 300,
            marginBottom: '.5em',
        },
    });
